"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnsplashImageDataStore = void 0;
var Types_1 = require("Project/ContentSidebar/Types");
var SidebarExploreDataStore_1 = require("../SidebarExploreDataStore");
var mobx_1 = require("mobx");
var ImageLibraryDataStore_1 = require("Common/AssetLibrary/ImageLibrary/ImageLibraryDataStore");
var UnsplashImageDataStore = (function (_super) {
    __extends(UnsplashImageDataStore, _super);
    function UnsplashImageDataStore(unsplashDataStore) {
        var _this = _super.call(this) || this;
        _this.unsplashDataStore = unsplashDataStore;
        _this.imageList = [];
        _this.collectionId = "";
        _this.searchTerm = "";
        _this.isLoadingData = true;
        _this.fetchNextIncrement = function (mode, filter) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingData = true;
                        if (!(mode === "collection")) return [3, 2];
                        return [4, this.getCollectionImage(filter)];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, this.getImagesBySearchTerm(filter)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.isLoadingData = false;
                        return [2];
                }
            });
        }); };
        _this.fetchRandomImages = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingData = true;
                        return [4, this.unsplashDataStore.getRandomImages()];
                    case 1:
                        _a.sent();
                        this.imageList = wrapUnsplashImage(this.unsplashDataStore.getCurrentImages);
                        this.isLoadingData = false;
                        return [2];
                }
            });
        }); };
        _this.clearImages = function () {
            _this.collectionId = "";
            _this.searchTerm = "";
            _this.imageList = [];
            _this.unsplashDataStore.emptyImageList();
        };
        _this.reset = function () {
            _this.isLoadingData = true;
            _this.clearImages();
        };
        return _this;
    }
    Object.defineProperty(UnsplashImageDataStore.prototype, "pageToFetchNext", {
        get: function () {
            return Math.floor(this.imageList.length / ImageLibraryDataStore_1.IMAGES_PER_PAGE);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnsplashImageDataStore.prototype, "loadedAssets", {
        get: function () {
            return this.imageList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnsplashImageDataStore.prototype, "getFeaturedAssets", {
        get: function () {
            return this.loadedAssets.slice(0, 10);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnsplashImageDataStore.prototype, "hasUnloadedAssets", {
        get: function () {
            return !this.unsplashDataStore.isOutOfNewData;
        },
        enumerable: false,
        configurable: true
    });
    UnsplashImageDataStore.prototype.search = function (term) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.getImagesBySearchTerm(term)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    UnsplashImageDataStore.prototype.getCollectionImage = function (collectionId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.collectionId !== collectionId) {
                            this.clearImages();
                            this.collectionId = collectionId;
                        }
                        return [4, this.unsplashDataStore.fetchCollectionImages(collectionId, this.pageToFetchNext)];
                    case 1:
                        _a.sent();
                        this.imageList = wrapUnsplashImage(this.unsplashDataStore.getCurrentImages);
                        return [2];
                }
            });
        });
    };
    UnsplashImageDataStore.prototype.getImagesBySearchTerm = function (searchTerm) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingData = true;
                        if (this.searchTerm !== searchTerm) {
                            this.clearImages();
                            this.searchTerm = searchTerm;
                        }
                        return [4, this.unsplashDataStore.searchImages(searchTerm, this.pageToFetchNext)];
                    case 1:
                        _a.sent();
                        this.imageList = wrapUnsplashImage(this.unsplashDataStore.getCurrentImages);
                        this.isLoadingData = false;
                        return [2];
                }
            });
        });
    };
    var _a, _b, _c;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Array)
    ], UnsplashImageDataStore.prototype, "imageList", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "collectionId", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "searchTerm", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "isLoadingData", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], UnsplashImageDataStore.prototype, "pageToFetchNext", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], UnsplashImageDataStore.prototype, "loadedAssets", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], UnsplashImageDataStore.prototype, "hasUnloadedAssets", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "fetchNextIncrement", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "fetchRandomImages", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_a = typeof Promise !== "undefined" && Promise) === "function" ? _a : Object)
    ], UnsplashImageDataStore.prototype, "search", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_b = typeof Promise !== "undefined" && Promise) === "function" ? _b : Object)
    ], UnsplashImageDataStore.prototype, "getCollectionImage", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
    ], UnsplashImageDataStore.prototype, "getImagesBySearchTerm", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "clearImages", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], UnsplashImageDataStore.prototype, "reset", void 0);
    return UnsplashImageDataStore;
}(SidebarExploreDataStore_1.BaseExploreDataStore));
exports.UnsplashImageDataStore = UnsplashImageDataStore;
var wrapUnsplashImage = function (unsplashImages) {
    return unsplashImages.map(function (unsplashImage) { return ({
        name: "",
        tags: [],
        type: Types_1.AssetType.UnsplashImage,
        content: {
            id: unsplashImage.id,
            thumbnailUrl: unsplashImage.thumbnailUrl,
            url: unsplashImage.url,
            altDescription: (unsplashImage === null || unsplashImage === void 0 ? void 0 : unsplashImage.altDescription) || undefined,
        },
    }); });
};
