"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllTab = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Carousel_1 = require("Project/ContentSidebar/Explore/SharedComponents/Carousel");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./AllTab.css"));
var AllTabThumbnail_1 = require("./AllTabThumbnail");
var SidebarExploreContext_1 = require("../SidebarExploreContext");
var SidebarExplore_1 = require("Project/ContentSidebar/Explore/SidebarExplore");
var inversify_react_1 = require("inversify-react");
var AllTab = function (_a) {
    var setTab = _a.setTab, onClickToAdd = _a.onClickToAdd, onDragAssetEnd = _a.onDragAssetEnd, onDragAssetStart = _a.onDragAssetStart;
    var _b = (0, SidebarExploreContext_1.useSidebarExploreContext)(), blockStore = _b.blockExploreDataStore, imageStore = _b.unsplashImageDataStore, videoStore = _b.videoExploreDataStore, searchTerm = _b.searchTerm;
    var discoverStore = (0, inversify_react_1.useInjection)("discoverStore");
    var _c = __read((0, react_1.useState)([]), 2), images = _c[0], setImages = _c[1];
    var _d = __read((0, react_1.useState)([]), 2), blocks = _d[0], setBlocks = _d[1];
    var _e = __read((0, react_1.useState)([]), 2), videos = _e[0], setVideos = _e[1];
    (0, react_1.useEffect)(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, imageStore.fetchRandomImages()];
                    case 1:
                        _a.sent();
                        setImages(imageStore.getFeaturedAssets);
                        return [2];
                }
            });
        }); };
        fetchData();
    }, []);
    (0, react_1.useEffect)(function () {
        if (searchTerm) {
            blockStore.search(searchTerm);
            var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, imageStore.search(searchTerm)];
                        case 1:
                            _a.sent();
                            setImages(imageStore.getFeaturedAssets);
                            return [2];
                    }
                });
            }); };
            fetchData();
            videoStore.search(searchTerm);
        }
        else {
            blockStore.clearSearch();
            var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, imageStore.fetchRandomImages()];
                        case 1:
                            _a.sent();
                            setImages(imageStore.getFeaturedAssets);
                            return [2];
                    }
                });
            }); };
            fetchData();
            videoStore.clearSearch();
        }
        setBlocks(blockStore.getFeaturedAssets);
        setVideos(videoStore.getFeaturedAssets);
    }, [searchTerm, blockStore, imageStore, videoStore]);
    var openHelpSideBar = function () {
        discoverStore.openChat();
    };
    return (react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingRight: "l", className: styles.container, gap: "l" },
        blockStore.loadedAssets.length > 0 && (react_1.default.createElement(kaleidoscope_1.Stack, { key: "blocks" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.header },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, lineHeight: "relaxed" }, "Blocks"),
                react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Small, className: styles.viewAll, type: kaleidoscope_1.ButtonType.Tertiary, onClick: function () { return setTab(SidebarExplore_1.SidebarExploreTab.Blocks); } }, "View all")),
            react_1.default.createElement(Carousel_1.Carousel, { itemsPerSlide: 2, items: blocks, className: styles.carousel, renderItem: function (asset) { return (react_1.default.createElement(AllTabThumbnail_1.AllTabThumbnail, { asset: asset, onClick: function () {
                        onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragStart: function () {
                        onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragEnd: function () {
                        onDragAssetEnd(asset);
                    } })); } }))),
        imageStore.isLoadingData ? (react_1.default.createElement(kaleidoscope_1.Spinner, null)) : (imageStore.loadedAssets.length > 0 &&
            !imageStore.isLoadingData && (react_1.default.createElement(kaleidoscope_1.Stack, { key: "images" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.header },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, lineHeight: "relaxed" }, "Images"),
                react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Small, className: styles.viewAll, type: kaleidoscope_1.ButtonType.Tertiary, onClick: function () {
                        setTab(SidebarExplore_1.SidebarExploreTab.Images);
                    } }, "View all")),
            react_1.default.createElement(Carousel_1.Carousel, { itemsPerSlide: 2, items: images, className: styles.carousel, renderItem: function (asset) { return (react_1.default.createElement(AllTabThumbnail_1.AllTabThumbnail, { asset: asset, onClick: function () {
                        onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragStart: function () {
                        onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragEnd: function () {
                        onDragAssetEnd(asset);
                    } })); } })))),
        videoStore.loadedAssets.length > 0 && (react_1.default.createElement(kaleidoscope_1.Stack, { key: "videos" },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", className: styles.header },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, lineHeight: "relaxed" }, "Videos"),
                react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Small, className: styles.viewAll, type: kaleidoscope_1.ButtonType.Tertiary, onClick: function () {
                        setTab(SidebarExplore_1.SidebarExploreTab.Videos);
                    } }, "View all")),
            react_1.default.createElement(Carousel_1.Carousel, { itemsPerSlide: 2, items: videos, className: styles.carousel, renderItem: function (asset) { return (react_1.default.createElement(AllTabThumbnail_1.AllTabThumbnail, { asset: asset, onClick: function () {
                        onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragStart: function () {
                        onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.All, withSearchTerm: !!searchTerm });
                    }, onDragEnd: function () {
                        onDragAssetEnd(asset);
                    } })); } }))),
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { onClick: function () { return openHelpSideBar(); }, className: styles.bannerContainer },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.content },
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.image },
                    react_1.default.createElement("img", { src: "/Assets/Images/onboarding/sidebar-help.png" })),
                react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingTop: "m", paddingBottom: "m", justify: "center" },
                    react_1.default.createElement(kaleidoscope_1.Stack, { paddingBottom: "xs" },
                        react_1.default.createElement(kaleidoscope_1.Label, { secondary: true, size: "l" }, "Need Help?")),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "xs" }, "Click to talk with someone or look at our help docs"))))));
};
exports.AllTab = AllTab;
