"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var VideoActions_1 = require("./VideoActions");
exports.default = (function (di) {
    var dragStore = di.get("dragStore");
    var dropLocationStore = di.get("dropLocationStore");
    var mediaUsageReporter = di.get("mediaUsageReporter");
    var contentSidebarTelemetry = di.get("contentSidebarTelemetry");
    var editorTelemetry = di.get("editorTelemetry");
    var blockStore = di.get("blockStore");
    return {
        onDragOverHoldingVideo: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDragVideoFromSidebar")
            .isEvent(EditorTypes_1.EventType.DragOver)
            .doAction(function (event, editor) {
            var handled = (0, VideoActions_1.maybeUpdateVideoDropLocation)(editor, event, dragStore, dropLocationStore);
            if (handled) {
                event.preventDefault();
                event.stopPropagation();
            }
            return handled;
        }),
        onDropVideoFromSidebar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDropVideoFromSidebar")
            .isEvent(EditorTypes_1.EventType.Drop)
            .doAction(function (event, editor) {
            var _a;
            var insertedVideo = (0, VideoActions_1.maybeInsertVideoFromSidebar)(editor, dragStore, dropLocationStore, mediaUsageReporter, blockStore.blockType, editorTelemetry);
            var tab = (_a = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarVideo)) === null || _a === void 0 ? void 0 : _a.telemetryData.tab;
            if (insertedVideo) {
                event.preventDefault();
                contentSidebarTelemetry.sidebarAssetAdded({
                    assetType: TelemetryEnums_1.SidebarAssetAddedType.VideoLink,
                    insertMethod: TelemetryEnums_1.SidebarAssetAddedInsertMethod.Drag,
                    source: "explore",
                    secondarySource: tab,
                });
            }
            return insertedVideo;
        }),
    };
});
